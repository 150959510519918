import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroBgImage from "../../components/asset-management/index/hero-image"
import Company from "../../components/asset-management/index/company"

const IndexPage = ({ location }) => (
    <Layout location={location}>
        <Seo title="Home" />
        <HeroBgImage />
        <Company />
    </Layout>
)

export default IndexPage
